import React from "react";
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import { SEO, Layout } from "../components/";

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO title={`404: ${intl.formatMessage({ id: "title" })}`} />
    <h1>
      <FormattedMessage id="notfound" />
    </h1>
    <p>
      <FormattedMessage id="notfound_text" />
    </p>
  </Layout>
);

export default injectIntl(NotFoundPage);
